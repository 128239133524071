<template>
  <div class="trainplan">
    <div class="trainplan-wrapper">
      <!-- <div class="introduction">
        <h3>活动规则</h3>
        <div class="describe flex-layout">
          <div class="cover-wrapper">
            <img :src="downloadURL + info.cover" alt="" class="cover"
              :onerror="$store.state.course.defaultActivityCoverStudyWall">
            <p class="default-tilel" v-if="!info.cover">主题活动</p>
          </div>
          <div>
            <p class="name"><span class="caption">活动名称：</span>{{(info && info.name) || '--'}}</p>
            <p class="general"><span class="caption">活动概况：</span>{{(info && info.introduce) || '--'}}</p>
          </div>
        </div>
      </div> -->
      <!-- <el-tabs v-model="activeName" class="tabs">
        <el-tab-pane label="活动规则" name="first"> -->
          <p class="content" v-if="!pdfUrl">暂无简介</p>
          <div class="pdf-wrapper" v-loading="loading" element-loading-text="正在加载pdf..." v-if="pdfUrl">
            <pdf :src="src" v-for="i in totalPage" :key="i" :page="i"></pdf>
          </div>
          <Empty :show="!info"></Empty>
        <!-- </el-tab-pane>
      </el-tabs> -->
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import pdf from 'vue-pdf'
  export default {
    components: {
      pdf
    },
    data() {
      return {
        activeName: 'first',
        info: {},
        totalPage: undefined,
        src: undefined,
        loading: false,
        pdfUrl: undefined,

      }
    },
    methods: {
      async getActivityDetail() {
        let resData = await this.$Api.Activity.getActivityDetail(this.$route.params.id)
        // console.log(resData);
        this.info = resData.data;
        if (resData.data.detail.indexOf('pdf') != -1) {
          this.pdfUrl = resData.data.detail;
          this.loading = true;
          this.loadingTask();
        }
      },
      loadingTask() {
        console.log(this.downloadURL + this.pdfUrl);
        // this.src = pdf.createLoadingTask(this.downloadURL + this.$route.params.md5)
        this.src = pdf.createLoadingTask(this.downloadURL + this.pdfUrl)
        this.src.promise.then(pdf => {
          this.loading = false;
          this.totalPage = pdf.numPages;
        })
      },
    },
    mounted() {
      this.getActivityDetail();
    }
  }
</script>

<style lang="less" scoped>
  @font-face {
    font-family: 'ktf';
    src: url("../../../../static/font/ktf.TTF");
  }

  .trainplan {
    // background-color: #F9F9F9;

    .trainplan-wrapper {
      width: 990px;
      margin: 0 auto;

      .breadcrumb {
        line-height: 80px;
      }

      .introduction {
        border: 1px solid #f2f2f2;
        padding: 0px 48px;
        border-radius: 10px;
        background-color: #FFFFFF;

        h3 {
          font-size: 13px;
          line-height: 50px;

          &::before {
            content: ' ';
            display: inline-block;
            width: 4px;
            height: 21px;
            background: linear-gradient(180deg, #186FF5, #307CF0);
            border-radius: 2px;
            margin-right: 11px;
            vertical-align: -5px;
          }
        }

        .describe {
          padding: 31px 16px;
          border-top: 1px solid #f5f4f4;
          font-size: 12px;
          line-height: 20px;

          .cover {
            width: 360px;
            height: 196px;
            border-radius: 8px;
            margin-right: 37px;
          }

          .cover-wrapper {
            position: relative;

            .default-tilel {
              font-family: 'ktf';
              position: absolute;
              color: #fff;
              font-size: 34px;
              top: 38%;
              left: 45px;
              z-index: 99;
            }
          }

          span.caption {
            font-weight: bold;
          }

          .name {
            margin: 12px 0 14px;
          }

        }
      }

      .tabs {
        margin: 19px 0 155px;
        padding: 0 47px;
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #f2f2f2;
      }

      /deep/ .el-tabs__header {
        background-color: #fff;
        margin: 0;

        .el-tabs__item {
          padding: 0 40px !important;
          font-size: 14px;
          // font-weight: bold;
          height: 49px;
          line-height: 49px;
        }

        .el-tabs__nav-wrap.is-top {
          padding: 0;
        }

        .el-tabs__item:hover {
          color: #508EF9;
        }

        .el-tabs__item.is-active {
          color: #508EF9;

        }
      }

      /deep/ .el-tabs__active-bar {
        height: 1px;
        background-color: #508EF9;
        margin-left: -40px;
      }

      /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f2f2f2;
      }

      /deep/ .el-tabs__nav.is-stretch>* {
        flex: 0 0 15%;
      }

      .content {
        width: 100%;
        padding: 24px 15px 50px;
      }

      .pdf-wrapper {
        height: calc(100vh - 200px);
        overflow-y: auto;
        margin-bottom: 50px;

        /*修改滚动条样式*/
        &::-webkit-scrollbar {
          width: 8px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }

        &::-webkit-scrollbar-button {
          height: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background: #bfbfbf;
          border-radius: 4px;
        }


      }
    }

    @media screen and (min-width:1250px) {
      .trainplan-wrapper {
        width: 1200px;

        .introduction {
          padding: 0 56px;


          h3 {
            font-size: 16px;
            line-height: 60px;
          }


          .describe {
            padding: 37px 19px;
            font-size: 14px;
            line-height: 24px;

            .cover {
              width: 436px;
              height: 237px;
              border-radius: 10px;
              margin-right: 45px;
            }

            .cover-wrapper {
              .default-tilel {
                font-size: 42px;
                top: 38%;
                left: 50px;
              }
            }

            .name {
              margin: 15px 0 18px;
            }

            .name,
            .general {
              font-size: 14px;
            }

          }
        }

        /deep/ .el-tabs__header {

          .el-tabs__item {
            font-size: 16px;
            height: 59px;
            line-height: 59px;
          }
        }

        .content {
          padding: 29px 18px 61px;
        }
      }

    }
  }
</style>